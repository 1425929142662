<template>
  <div class="module-wrapper">
    <div class="title-tile">
      <div class="main-title">
        <h3 class="module-type" v-if="this.data.attributes.field_module_term || this.data.attributes.field_module_type">{{ 
          this.data.attributes.field_module_term + ' - ' + 
          this.data.attributes.field_module_type  
        }}</h3>
        <h2 class="module-title">{{ this.data.attributes.title }}</h2>
      </div>
      <div class="secondary-title">
        <h4 class="lecturer-label">lecturer</h4>
        <h3 class="lecturer-name" v-for="(lec, index) in this.data.field_module_lecturer_ref" :key="'lecturer'+index">
          <router-link :to="personLink(lec)">
            {{ lec.attributes.field_first_name + ' ' + lec.attributes.field_last_name }}
          </router-link>
        </h3>
      </div>
    </div>

    <div class="content-tile">
      <div class="info">
        <div class="room" v-if="this.data.attributes.field_module_location">
          <label >room</label>
          {{ this.data.attributes.field_module_location }}
        </div>
        <div class="date" v-if="this.data.attributes.field_module_date_from">
          <label>DATE</label>
          {{newDateFrom + ' - ' + newDateTo}}
        </div>
        <div class="lectures" v-if="this.data.attributes.field_module_lecturers_hours">
          <label>lectures</label>
           {{ this.data.attributes.field_module_lecturers_hours + ' hours' }}
          </div>
        <div class="labs" v-if="this.data.attributes.field_module_labs_hours">
          <label>labs</label>
          {{ this.data.attributes.field_module_labs_hours + ' hours' }}
          </div>
        <div class="credits" v-if="this.data.attributes.field_module_credits">
          <label>credits</label>
          {{ this.data.attributes.field_module_credits }}</div>
      </div>
      <div class="description"> <p v-html="this.data.attributes.field_module_description.processed"></p></div>
    </div>
  </div>    
</template>

<script>
import { reWriteDate, detailsLink } from '../libs/utils'

export default {
  name: 'module',
  data: () => {
    return {
    }
  },
  props: {
    data: Object
  },
  computed:{
    newDateFrom() {
      if(this.data.attributes.field_module_date_from)
        return reWriteDate(this.data.attributes.field_module_date_from)
      else 
        return ''
    },
    newDateTo() {
      if(this.data.attributes.field_module_date_to)
        return reWriteDate(this.data.attributes.field_module_date_to)
      else 
        return ''
    }
  },
  methods: {
    personLink(data) {
      if(data)
        return detailsLink('person', data)
      else return '#'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors.scss';

.module-wrapper {
  display: flex; display: -webkit-flex;
  flex-direction: column;
  min-height: 480px;
  margin-bottom: 2rem;
  @media (min-width: 992px) {
    flex-direction: row;
  }
  width: 100%;
  .description {
    padding-top: 2rem;
    font-weight: 500;
    border-top: 1px solid #d3d3d3;
  }
}

.title-tile {
  width: 100%;
  @media (min-width: 992px) {
    width: 30%;
  }
  background-color: $mainColor;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  background-image: url("../assets/pattern-modules-w.png");
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: bottom left;


  &::after {
    position: absolute;
    display: block;
    width: 22px;
    height: 22px;
    content: "";
    top: 20px;
    right: 20px;
    border-style: solid;
    border-color: #fff;
    border-width: 7px 7px 0px 0px;
  }

  .main-title {
    padding: 2rem;
    padding-bottom: 10px;
    padding-top: 20%;
    @media (min-width: 992px) {
      display: flex; display: -webkit-flex;
      flex-direction: column;
      justify-content: flex-start;
      //justify-content: flex-end;
      padding: 2rem;
    }
    //height: 100%;
    border-bottom: 1px #fff solid;
    
    .module-title {
      color: #000;
      padding: 0;
      font-size: 2.125rem;
      
    }
    .module-type {
      padding: 0;
      color: #000;
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .secondary-title {
    padding: 2rem ;

    .lecturer-name {
      padding-bottom: 0;
      &:not(:first-of-type) {
        padding-top: 0;
      }
      a {
        text-decoration: none;
        color: #fff;
        font-size: 1.75rem;
        padding: 0;
        text-transform: none;
        &:hover {
          color: $secondaryColor;
          text-decoration: underline;
        }
      }
    }

    .lecturer-label {
      font-size: 0.9375rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.content-tile {
  background-color: #fff;
  padding: 1em 2em;
  width: calc(100% - 3em);
  display: flex; display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 992px) {
    padding: 4em 5em;
    width: 70%;
  }
  

  .info {
    display: flex; display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding:2rem 0rem ;

    &> div:not(:last-child) {
      margin-right: 3em;
    }

    &> div {
      position: relative;
    }

    .room,
    .date,
    .lectures,
    .labs,
    .credits {
      font-weight: 500; 
      font-size: 1.25rem;
      display: flex; display: -webkit-flex;
      flex-direction: column;

      
    }

    .date {
      width: auto;
    }
  }
}
label {
    font-weight: 500; 
    font-size: 1rem;
    text-transform: uppercase;
    display: block;
    color: darkgray;
    top: -.25em;
    @media (min-width: 992px) {
      top: 1em;
    }
    left: 0;
  }


@media (max-width:1500px) {
  .title-tile {
      width: 40%;
  }
  .content-tile {
    width: 60%;
    padding: 2em 3em;
    .info {
      div {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width:1200px) {
  .content-tile {
    .info {
      justify-content: flex-start;
    }
  }
}
@media (max-width:992px) {

  .title-tile {
      width: 100%;
      background-size:40%;
      .main-title{
        padding:2rem ;
      }
  }
  .content-tile {
    width: 100%;
    padding:  2em 2em;
    .info {
      padding-top: 0;
      div {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width:768px) {
  .content-tile {
    .info {
      justify-content: flex-start;
     
    }
  }
}
@media (max-width:576px) {
  .content-tile,
  .title-tile .secondary-title{
    padding: 1.4rem;
  }

  .title-tile .main-title {
    padding: 3rem 1.4rem 1.4rem 1.4rem;
  }

  .title-tile{
      &::after{
        width: 16px;
        height: 16px;
      }
      .main-title {
        .module-type {
          font-size: 0.875rem;
        }
        .module-title {
          font-size: 1.75rem;
        }
      }
      .secondary-title {
        .lecturer-label {
          font-size: 0.875rem;
        }
         .lecturer-name {
            font-size: 1.375rem;
         }
      }
    
  }
  .content-tile{
    .info {
      .lectures,
      .date,
      .labs,
      .credits,
      .room {
        font-size: 1rem;
      }
    }
  }
  label {
      font-size: 0.875rem;
    }

}
</style>