<template>
    <div class="template-page modules grey-bg">
        <basic-page :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div class="body-content" v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>
            <transition-group name="list" tag="div" >
                <div v-for="(mod, index) in this.modulesData" :key="'module'+index">
                    <module :data="mod"></module>
                </div>
            </transition-group>
        </basic-page>
    </div>
</template>

<script>
import Module from '../components/Module.vue'
import BasicPage from '../views/BasicPage'
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
export default {
    name: 'modules',
    components: {
        Module,
        BasicPage
    },
    data: () => {
        return {
            modulesData: {},
            pageData: {},
            currentPageId: "4facb035-d0ce-4964-a53d-61fb24d3bf39",
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top']})
            .then(res => {
                this.pageData = res[0].attributes
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
        fetchNodes('module', {
            include: ['field_module_lecturer_ref', 'node_type']
        }).then(res => {
            //console.log(res)
            this.modulesData = res
        })
    }
}
</script>

<style lang="scss" >
@import "../styles/colors.scss";

#app .main-content > .container > .body-content {
    margin-bottom: 2rem;
}

</style>